import React from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_slovkcatscriminalguessrightScreen = (props) => {
    return (
        <div id="game" style={{ textAlign: "center", padding: "20px", backgroundColor: "#ffe6e6" }}>
            <SlovHeader />

            <img 
                src={props.PColors !== undefined 
                    ? `https://cyberbox.trgu.ru/public/avatars/crim/PlayerMain${props.PColors + 1}.png`
                    : "Аватар загружается\n"} 
                alt="Player Avatar" 
                style={{ marginTop: "20px", width: "100px", height: "100px", objectFit: "cover" }}
            />

            <div style={{ fontSize: "20px", marginTop: "10px", fontWeight: "bold", color: "#333" }}>
                {props.prName}
            </div>

            <div style={{ fontSize: "18px", marginBottom: "20px", color: "#666" }}>
                {props.slovGamePoints} очков
            </div>

            <div style={{ 
                marginTop: "30px", 
                padding: "20px", 
                border: "2px solid #ff4d4d", 
                borderRadius: "10px", 
                backgroundColor: "#fff5f5" 
            }}>
                <h1 style={{ color: "#ff0000", fontWeight: "bold", fontSize: "36px" }}>
                    🎉 Поздравляем! Вы угадали убийцу! 🎉
                </h1>
                <React.Fragment>
                    <p style={{ fontSize: "18px", marginTop: "20px", lineHeight: "1.6", color: "#333" }}>
                        Отличная работа! Ваша догадка оказалась верной. 
                        Однако, пожалуйста, держите эту информацию при себе — пусть другие игроки также попытаются разгадать тайну.
                    </p>
                    <p style={{ fontSize: "18px", marginTop: "10px", lineHeight: "1.6", fontStyle: "italic", color: "#666" }}>
                        Попробуйте проверить кого-то другого в следующий раз, так как ведущий имеет особые реплики на определённые сочетания!
                    </p>
                    <div style={{ marginTop: "20px", fontSize: "24px", fontWeight: "bold", color: "#ff6666" }}>
                        💬 "В конце мы обязательно упомянем, какой вы умничка и на каком этапе разгадали загадку!" 💬
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
};