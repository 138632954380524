import React, { useState, useEffect } from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_slovkcatscriminalguessScreen = (props) => {
    const [selectedWeapon, setSelectedWeapon] = useState(null);
    const [selectedClue, setSelectedClue] = useState(null);
    const [selectedKiller, setSelectedKiller] = useState(null);
    const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);

    const handleSelection = (killerIndex, type, itemIndex) => {
        if (type === "weapon") {
            setSelectedWeapon({ killerIndex, itemIndex });
        } else if (type === "clue") {
            setSelectedClue({ killerIndex, itemIndex });
        }
    };

    useEffect(() => {
        if (
            selectedWeapon?.killerIndex === selectedClue?.killerIndex &&
            selectedWeapon?.killerIndex !== undefined &&
            selectedClue?.killerIndex !== undefined
        ) {
            setSelectedKiller(selectedWeapon.killerIndex);
            setIsConfirmEnabled(true);
        } else {
            setSelectedKiller(null);
            setIsConfirmEnabled(false);
        }
    }, [selectedWeapon, selectedClue]);

    const handleConfirm = () => {
        if (isConfirmEnabled) {
            const { itemIndex: weaponIndex } = selectedWeapon;
            const { itemIndex: clueIndex } = selectedClue;
            props.sendMessage(`sendtoserver|slov\\buttonchosen\\guess\\${selectedKiller + 1}\\${weaponIndex + 1}\\${clueIndex + 1}`);
            console.log(`Подтверждено: убийца ${selectedKiller + 1}, орудие ${weaponIndex + 1}, улика ${clueIndex + 1}`);
        }
    };

    return (
        <div id="game">
            <SlovHeader />
            <React.Fragment><br /></React.Fragment>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {Array.from({ length: 8 }).map((_, killerIndex) => (
                    <div 
                        key={killerIndex} 
                        style={{ display: "flex", alignItems: "center", border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }}
                    >
                        {/* Killer Avatar */}
                        <img 
                            src={`https://cyberbox.trgu.ru/public/crim/killer_${killerIndex + 1}_avatar.png`} 
                            alt={`Убийца ${killerIndex + 1}`} 
                            style={{ width: "140px", height: "200px", objectFit: "cover", marginRight: "10px" }}
                        />

                        {/* Weapons and Clues */}
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                {Array.from({ length: 4 }).map((_, weaponIndex) => (
                                    <div key={weaponIndex} style={{ position: "relative" }}>
                                        <img 
                                            src={`https://cyberbox.trgu.ru/public/crim/killer_${killerIndex + 1}_weapon_${weaponIndex + 1}.png`} 
                                            alt={`Орудие ${weaponIndex + 1}`} 
                                            style={{ width: "60px", height: "100px", objectFit: "cover", cursor: "pointer" }}
                                            onClick={() => handleSelection(killerIndex, "weapon", weaponIndex)}
                                        />
                                        {selectedWeapon?.killerIndex === killerIndex && selectedWeapon.itemIndex === weaponIndex && (
                                            <div 
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                    width: "20px",
                                                    height: "20px",
                                                    background: "green",
                                                    borderRadius: "50%",
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div style={{ display: "flex", gap: "10px" }}>
                                {Array.from({ length: 4 }).map((_, clueIndex) => (
                                    <div key={clueIndex} style={{ position: "relative" }}>
                                        <img 
                                            src={`https://cyberbox.trgu.ru/public/crim/killer_${killerIndex + 1}_clue_${clueIndex + 1}.png`} 
                                            alt={`Улика ${clueIndex + 1}`} 
                                            style={{ width: "60px", height: "100px", objectFit: "cover", cursor: "pointer" }}
                                            onClick={() => handleSelection(killerIndex, "clue", clueIndex)}
                                        />
                                        {selectedClue?.killerIndex === killerIndex && selectedClue.itemIndex === clueIndex && (
                                            <div 
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                    width: "20px",
                                                    height: "20px",
                                                    background: "green",
                                                    borderRadius: "50%",
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Confirm Button */}
            <button 
                type="button" 
                onClick={handleConfirm} 
                disabled={!isConfirmEnabled}
                style={{ 
                    marginTop: "20px", 
                    padding: "10px 20px", 
                    backgroundColor: isConfirmEnabled ? "green" : "gray", 
                    color: "white", 
                    border: "none", 
                    borderRadius: "5px", 
                    cursor: isConfirmEnabled ? "pointer" : "not-allowed"
                }}
            >
                Подтвердить
            </button>
        </div>
    );
};
