import React, {useState, useEffect} from 'react';
//import useWebSocket from 'react-use-websocket';
import useWebSocket from 'react-use-websocket';
//import io from 'socket.io-client';





// PROTESTATION SCREENS ------------------------------------------------------------------------------
import { SlovLobbyScreen } from './slovLobby';
import { SlovTypeScreen } from './slovType';
import { SlovTriviaScreen } from './slovTrivia';
import { SlovColorcodeScreen } from './slovColorcode';
import { SlovGlassScreen } from './slovGlass';
import { SlovVoiceScreen } from './slovVoice';
import { SlovLocationScreen } from './slovLocation';
import { SlovLotteryScreen } from './slovLottery';
import { SlovRowsScreen } from './slovRows';
import { SlovCellsScreen } from './slovCells';

// KCATS NIGHT (DELETE AFTER NIGHT) ------------------------------------------------------------------
import { KCATSNIGHT_SlovWaitingScreen } from './KCATSNIGHT_slovWaiting';
import { KCATSNIGHT_SlovMemesSendScreen } from './KCATSNIGHT_slovMemesSend';
import { KCATSNIGHT_SlovMemesSend2Screen } from './KCATSNIGHT_slovMemesSend2';
import { KCATSNIGHT_slovMemesVoteScreen } from './KCATSNIGHT_slovMemesVote';
import { KCATSNIGHT_slovButtonScreen } from './KCATSNIGHT_slovButton';
import { KCATSNIGHT_slovTypeScreen } from './KCATSNIGHT_slovType';
// ---------------------------------------------------------------------------------------------------

// FIBBY.IO (DELETE AFTER NIGHT) ------------------------------------------------------------------
import { KCATSNIGHT_slovkcatsfibbychoiceScreen } from './KCATSNIGHT_slovkcatsfibbychoice';
import { KCATSNIGHT_slovkcatsfibbylieScreen } from './KCATSNIGHT_slovkcatsfibbylie';
import { KCATSNIGHT_slovkcatsfibbytruthScreen } from './KCATSNIGHT_slovkcatsfibbytruth';
import { KCATSNIGHT_slovkcatsfibbypickScreen } from './KCATSNIGHT_slovkcatsfibbypick';
// ---------------------------------------------------------------------------------------------------



// CRIMINALIST (DELETE (OR NOT) AFTER NIGHT) ------------------------------------------------------------------
import { KCATSNIGHT_slovkcatscriminalhostScreen } from './KCATSNIGHT_slovkcatscriminalhost';
import { KCATSNIGHT_slovkcatscriminalguessScreen } from './KCATSNIGHT_slovkcatscriminalguess';
import { KCATSNIGHT_slovkcatscriminalguessrightScreen } from './KCATSNIGHT_slovkcatscriminalguessright';
/*
import { KCATSNIGHT_slovkcatscriminalvoteforskipScreen } from './KCATSNIGHT_slovkcatscriminalvoteforskip';
import { KCATSNIGHT_slovkcatscriminalwaitScreen } from './KCATSNIGHT_slovkcatscriminalwait';
*/

// ---------------------------------------------------------------------------------------------------





 const socketUrl = 'wss://cyberbox.trgu.ru/api/';



export const SlovMainGame = (props) =>
{
const [returnstring, setreturnstring] = useState("");
const [GScreen, setGScreen] = useState(0);
const [slovLastMsg, setslovLastMsg] = useState("");
const [GNotStarted, setGNotStarted] = useState(false);
const [slovGamePoints, setslovGamePoints] = useState(0);
const [SentInfo, setSentInfo] = useState("");
const [SentInfo2, setSentInfo2] = useState("");
const [SentInfo3, setSentInfo3] = useState("");
const [SentInfo4, setSentInfo4] = useState("");
useEffect(() => {
    setGScreen(0);//-------------------------------------------------------------------------------ЭКРАНЫ
  }, []);

const handleSlovMessage = (Wmsg) =>
{
  console.log("slovoblud raw + " + Wmsg); 
  if(Wmsg.includes("\\"))
  {
  var msg = Wmsg.split("\\");
  console.log("slovoblud + " + msg);
  switch (msg[1])
  {
    case "setSlovPoints":
      setslovGamePoints(msg[2]);
    break;
    case "startscreen":
    setGNotStarted(true);
    setGScreen(0);
    break;
    case "typescreen":
    setGScreen(1);
    break;
    case "triviascreen":
    setGScreen(2);
    break;
    case "colorcodescreen":
    setGScreen(3);
    break;
    case "glassscreen":
    setGScreen(4);
    break;
    case "voicescreen":
    setSentInfo(msg[2]);
    setSentInfo2(msg[3]);
    setSentInfo3(msg[4]);
    setGScreen(5);
    break;
    case "locationscreen":
    setSentInfo(msg[2]);
    setGScreen(6);
    break;
    case "lotteryscreen":
    setGScreen(7);
    break;
    case "rowsscreen":
    setSentInfo(msg[2]);
    setSentInfo2(msg[3]);
    setGScreen(8);
    break;
    case "cellsscreen":
    setSentInfo(msg[2]);
    setGScreen(9);
    break



    // KCATS NIGHT SCREENS
    case "kcatswaitingscreen":
    setGScreen(100);
    break
    case "kcatsmemingsendscreen":
    setSentInfo(msg[2]);
    setSentInfo2(msg[3]);
    setGScreen(101);
    break;
    case "kcatsmemingsend2screen":
    setSentInfo(msg[2]);
    setSentInfo2(msg[3]);
    setGScreen(103);
    break;
    case "kcatsmemingvotescreen":
    setSentInfo(msg[2]);
    setSentInfo2(msg[3]);
    setGScreen(102);
    break;
    case "kcatsbutton":
    setGScreen(104);
    break
    case "kcatstype":
    setGScreen(105);
    break


    //FIBBY.IO
    case "slovkcatsfibbychoice":
    setGScreen(106);
    break
    case "slovkcatsfibbylie":
    setGScreen(107);
    break
    case "slovkcatsfibbytruth":
    setSentInfo(msg[2]);
    setGScreen(108);
    break
    case "slovkcatsfibbypick":
    setSentInfo(msg[2]);
    setGScreen(109);
    break

    //CRIMINALIST
    case "slovkcatscriminalhost":
    setGScreen(110);
    break
    case "slovkcatscriminalguess":
    setGScreen(111);
    break
    case "slovkcatscriminalguessright":
    setGScreen(112);
    break
    /*
    case "slovkcatscriminalvoteforskip":
    setGScreen(113);
    break
    case "slovkcatscriminalwait":
    setGScreen(114);
    break
    */
  }
}
}
/*
if(protLastMsg != props.WSMessage)
{
console.log(typeof WSMessage);
setprotLastMsg(props.WSMessage);
handleProtMessage(props.WSMessage);
}
*/
useEffect(() => {
  handleSlovMessage(props.WSMessage);
}, 
[props.WSMessage]);
/*
GameStates
|| Протестейшн
0 - (Протестейшн) Лобби протестейшна
1 - (Протестейшн) Туториал протестейшна
2 - (Протестейшн) Выбор темы протестейшна
3 - (Протестейшн) Экран лектора протестейшна
4 - (Протестейшн) Экран игрока протестейшна
5 - (Протестейшн) Экран судьи протестейшна
6 - (Протестейшн) Экран принятия протеста
7 - (Протестейшн) Экран оценки судьи
*/
useEffect(() => {
    switch (GScreen)
    {
    case 0:
    setreturnstring(<SlovLobbyScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 1:
    setreturnstring(<SlovTypeScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 2:
    setreturnstring(<SlovTriviaScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 3:
    setreturnstring(<SlovColorcodeScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 4:
    setreturnstring(<SlovGlassScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 5:
    setreturnstring(<SlovVoiceScreen ColorString = {SentInfo} TextString = {SentInfo2} TextString2 = {SentInfo3}  PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 6:
    setreturnstring(<SlovLocationScreen TextString = {SentInfo}  PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 7:
    setreturnstring(<SlovLotteryScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 8:
    setreturnstring(<SlovRowsScreen TextString = {SentInfo} TextString2 = {SentInfo2}  PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 9:
    setreturnstring(<SlovCellsScreen TextString = {SentInfo} PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;



    
    // KCATS NIGHT SCREENS
    case 100:
    setreturnstring(<KCATSNIGHT_SlovWaitingScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 101:
    setreturnstring(<KCATSNIGHT_SlovMemesSendScreen TextString = {SentInfo} TextString2 = {SentInfo2} PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 102:
    setreturnstring(<KCATSNIGHT_slovMemesVoteScreen TextString = {SentInfo} TextString2 = {SentInfo2} PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 103:
    setreturnstring(<KCATSNIGHT_SlovMemesSend2Screen TextString = {SentInfo} TextString2 = {SentInfo2} PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 104:
    setreturnstring(<KCATSNIGHT_slovButtonScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 105:
    setreturnstring(<KCATSNIGHT_slovTypeScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;

    //FIBBY.IO
    case 106:
    setreturnstring(<KCATSNIGHT_slovkcatsfibbychoiceScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 107:
    setreturnstring(<KCATSNIGHT_slovkcatsfibbylieScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 108:
    setreturnstring(<KCATSNIGHT_slovkcatsfibbytruthScreen TextString = {SentInfo} PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 109:
    setreturnstring(<KCATSNIGHT_slovkcatsfibbypickScreen TextString = {SentInfo} PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;

    //CRIMINALIST
    case 110:
    setreturnstring(<KCATSNIGHT_slovkcatscriminalhostScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 111:
    setreturnstring(<KCATSNIGHT_slovkcatscriminalguessScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 112:
    setreturnstring(<KCATSNIGHT_slovkcatscriminalguessrightScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
        /*
    case 113:
    setreturnstring(<KCATSNIGHT_slovkcatscriminalvoteforskipScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    case 114:
    setreturnstring(<KCATSNIGHT_slovkcatscriminalwaitScreen  PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} slovGamePoints = {slovGamePoints}/>);
    break;
    */

  } 
}, [GScreen, slovGamePoints, SentInfo, SentInfo2, SentInfo3, SentInfo4]);
return(
  returnstring
  );
}