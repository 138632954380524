import React, { useEffect } from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_slovkcatscriminalhostScreen = (props) => {
    useEffect(() => {
        props.CBcolor("#545454");
    }, []);


    return (
        <div id="game">
            <SlovHeader />

            <img 
                src={props.PColors !== undefined 
                    ? `https://cyberbox.trgu.ru/public/avatars/crim/PlayerMain${props.PColors + 1}.png`
                    : "Аватар загружается\n"} 
                alt="Player Avatar" 
            />

            <React.Fragment><br /></React.Fragment>

            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br /></React.Fragment>

            <div className="divtext_slov"><b>{props.slovGamePoints}</b> очков</div>
            <React.Fragment><br /></React.Fragment>

            {/* Grid of 8 buttons */}
            <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "10px", margin: "20px 0" }}>
                {Array.from({ length: 8 }).map((_, index) => (
                    <button 
                        key={index} 
                        type="button" 
                        style={{
                            width: "60px", 
                            height: "60px", 
                            fontSize: "24px", 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center"
                        }}
                        onClick={() => {
                            props.sendMessage(`sendtoserver|slov\\buttonchosen\\zoom\\${index + 1}`);
                            console.log(`Кнопка ${index + 1} нажата`);
                        }}
                    >
                        🔍
                    </button>
                ))}
            </div>

            {/* Bottom row buttons */}
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                <button 
                    type="button" 
                    onClick={() => {
                        props.sendMessage("sendtoserver|slov\\buttonchosen\\zoomhints");
                        console.log("Кнопка 'Показания' нажата");
                    }}
                >
                    Показания
                </button>

                <button 
                    type="button" 
                    onClick={() => {
                        props.sendMessage("sendtoserver|slov\\buttonchosen\\zoomtimer");
                        console.log("Кнопка 'Время' нажата");
                    }}
                >
                    Время
                </button>

                <button 
                    type="button" 
                    onClick={() => {
                        props.sendMessage("sendtoserver|slov\\buttonchosen\\zoomout");
                        console.log("Кнопка 'Общий план' нажата");
                    }}
                >
                    Общий план
                </button>
            </div>
        </div>
    );
};

